/* You can add global styles to this file, and also import other style files */
html {
  box-sizing: border-box;
  overflow: hidden;

  --mat-snack-bar-button-color: #88b767 !important;
  --mdc-checkbox-selected-focus-icon-color: #d35917 !important;
  --mdc-checkbox-selected-hover-icon-color: #d35917 !important;
  --mdc-checkbox-selected-icon-color: #d35917 !important;
  --mdc-checkbox-selected-pressed-icon-color: #d35917 !important;

  --mdc-checkbox-selected-focus-state-layer-color: #d35917 !important;
  --mdc-checkbox-selected-hover-state-layer-color: #d35917 !important;
  --mdc-checkbox-selected-pressed-state-layer-color: #d35917 !important;


  .gpdr-switcher {

  .mdc-label {
    font: normal normal normal 12px/16px Poppins;
  }
  ::ng-deep label {
    margin: 0;
  }
}
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  width: 100dvw;
  height: 100dvh;

}
p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

markdown h6 {
  font-size: 16px;
}
markdown h5 {
  font-size: 18px;
}
markdown h4 {
  font-size: 20px;
}
markdown h3 {
  font-size: 22px;
}
markdown h2 {
  font-size: 26px;
}
markdown h1 {
  font-size: 30px;
}
label {
  margin: 0 !important;
}

markdown a {
  word-break: break-all;
}

markdown code {
  white-space: break-spaces;
  word-break: break-all;
}

markdown img {
  max-width: 100%;
  height: auto;
}

markdown .highlight {
  text-decoration: underline;
}

markdown {
  thead {
    border-bottom: 2px solid #ababab;
  }

  th {
    padding: 5px 10px;

    &:not(:last-child) {
      border-right: 1px solid #cfcfcf;
    }

    text-align: center;
  }

  td {
    padding: 5px 10px;

    &:not(:last-child) {
      border-right: 1px solid #cfcfcf;
    }
  }
}

